<template>
  <page fluid="lg" title="Editar causa">
    <formulario :causa="causa" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiCausas from "@/services/causas.js";

export default {
  components: { Formulario },
  data() {
    return {
      causa: {},
    };
  },
  methods: {
    async cargar(id) {
      let resultado = await apiCausas.cargar(id);
      this.causa = resultado;
    },
    async actualizar() {
      let ok = await apiCausas.actualizar(this.causa);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado la causa");
        this.$router.push({ path: "/administracion/causas" });
      }
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
